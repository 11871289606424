import './App.css';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Controller, EffectCoverflow, Autoplay } from 'swiper/modules';
import projects from './projects.json';
import { Link } from 'react-router-dom';
export function Portfolios(props){
    return <div
            className="full bg-dark-4"
            style={{
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
                flexWrap:'wrap',
                height:'auto !important',
                minHeight:'100vh',
                height:'auto'
            }}
    >
        <div className='portfolios-frame'>
            {Object.values(projects).map(item=>(
                <Link to={`/portfolio/${item?.key}`} className='image-grid' key={item?.key}>
                    <img src={item?.featured_image} />
                </Link>
            ))}
        </div>
    </div>
}