import { Select, TextField } from "@mui/material";
import { alpha, styled } from '@mui/material/styles';

export const SelectField = styled(Select)({
        // '& label.Mui-focused': {
        //   color: '#A0AAB4',
        // },
        // '& label':{
        //     color: '#A0AAB4',
        // },
        '& .MuiSelect-select':{borderColor: '#E0E3E7',
        maxWidth: 325,
        minWidth:300},
        '& .MuiSelect-outlined':{
            borderColor: '#A0AAB4',
            maxWidth: 325,
            minWidth:300
          },
        '& .MuiInputBase-input':{
            color: '#A0AAB4',
        },
        '& .MuiInput-underline:after': {
          borderBottomColor: '#B2BAC2',
        },
        '& MuiInputBase-root':{minWidth:300},
        '& .MuiOutlinedInput-root': {
            maxWidth: 325,
            minWidth:"300px !important",
          '& fieldset': {
            borderColor: '#E0E3E7 !important',
          },
          '&:hover fieldset': {
            borderColor: '#B2BAC2 !important',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#6F7E8C !important',
          },
        },
        '& .MuiOutlinedInput-notchedOutline':{
            
              borderColor: '#E0E3E7 !important',

        },'& .MuiSelect-icon':{color:'white'},
        '& .MuiOutlinedSelect-root': {
          '& fieldset': {
            borderColor: '#E0E3E7',
          },
          '&:hover fieldset': {
            borderColor: '#B2BAC2',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#6F7E8C',
          },
        },
      });
