import { TextField } from "@mui/material";
import { alpha, styled } from '@mui/material/styles';

export const TextInput = styled(TextField)({
        '& label.Mui-focused': {
          color: '#A0AAB4',
        },
        '& label':{
            color: '#A0AAB4',
        },
        '& .MuiInputBase-input':{
            color: '#A0AAB4',
            maxWidth: 360,
            minWidth:300
        },
        '& .MuiInput-underline:after': {
          borderBottomColor: '#B2BAC2',
        },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: '#E0E3E7',
          },
          '&:hover fieldset': {
            borderColor: '#B2BAC2',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#6F7E8C',
          },
        },
      });
