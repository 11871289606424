import { Close } from "@mui/icons-material";
import { useCallback, useEffect, useState } from "react";

const Burger = ({isHover,...props}) =>{
    
    return (
        <div className="burger">
            <div className="burger-line"></div>
            <div className="burger-line"></div>
            <div className={`burger-line${!isHover ? ' short' : ''}`}></div>
        </div>
    );
}

export function MenuButton({onOpen, onClose, ...props}){
    const [isHover, set_hover] = useState(false);
    const [isOpen, toggle] = useState(false);
    const hover_open = useCallback(()=>{set_hover(true)},[]);
    const hover_close = useCallback(()=>{set_hover(false)},[]);
    const open = useCallback(()=>{toggle(true)},[]);
    const close = useCallback(()=>{toggle(false)},[]);
    const toggler = ()=>{
        if(isOpen){
            onClose?.(false);
            close();
        }else{
            onOpen?.(true);
            open();
        }
    }
    useEffect(()=>{
        if(props?.open===false)
            close()
    },[props?.open])
    return (
        <div onClick={toggler} className="menu-button" onMouseEnter={hover_open} onMouseLeave={hover_close}>
            {isOpen ? <Close fontSize={"large"} htmlColor={isHover ? "white" : "#F9BF26"} /> : <Burger isHover={isHover} />}
            <span>MENU</span>
        </div>
    )
}