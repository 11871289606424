// import logo from './logo.svg';
import { Link, Outlet, RouterProvider, createBrowserRouter, useNavigate } from 'react-router-dom';
import './App.css';
import { useEffect, useRef, useState } from 'react';
import { gsap, ScrollTrigger, Draggable, MotionPathPlugin } from "gsap/all";
// import { useGSAP } from '@gsap/react';
import logo from './assets/images/namariq-white.png'
import { MenuButton } from './components/menu-button';
import Home from './home';
import { Portfolio } from './portfolio';
import { Portfolios } from './portfolios';
import { ChevronRight, Mail, ArrowUpward } from '@mui/icons-material';
// don't forget to register plugins
//gsap.registerPlugin(ScrollTrigger, useGSAP, Draggable, MotionPathPlugin); 

function FixedComponentFrame(props){
  const [open, setOpen] = useState(false);
  const [scrollUp, setScrollUp] = useState(window?.scrollY > window?.innerHeight);
  const nav = useNavigate();
  const navigate = (to="", sub=null)=>{
    nav(to);
    if(sub!==null)
      setTimeout(()=>gsap.to(window, {scrollTo:sub, duration:2}),500)
    setOpen(false);
  }
  const getMenuWidth=()=>{
    if(window.innerWidth < 500)
      return "90%"
    if(window.innerWidth < 600)
      return "70%"
    if(window.innerWidth < 700)
      return "60%"
    if(window.innerWidth < 900)
      return "50%"
    return '40%';
  }
  useEffect(()=>{
    const scrollListener = window.addEventListener('scroll',()=>{
      if(window?.scrollY > (window?.innerHeight-150)){
        setScrollUp(true);
      }
      if((window?.innerHeight-150) > window?.scrollY){
        setScrollUp(false);
      }
    },true)

    return ()=> window.removeEventListener('scroll',scrollListener)
  },[])
  return (
    <div id='fixedcomp'>
      <div className='.fixed-header'>
        <Link to={"/"} className='logo-frame'>
          <img src={logo} />
        </Link>
        <div className='header-top-frame'>
          {window?.innerWidth <=1000 ? 
              <div className='inner'><MenuButton open={open} onOpen={setOpen} onClose={setOpen} /></div>
            : <div className='inner'>
                <div className='small-line'/>
                <span>Namariq</span>
              </div>
            }
          {window?.innerWidth <=1000 ? 
            <Mail  onClick={()=>navigate("/","#contact")} fontSize='medium' htmlColor='#F9BF26' />
            :
          <a className='inner right'>
            <span onClick={()=>navigate("/","#contact")}>get in touch</span>
          </a>
        }
        </div>
        <div className='header-sidebar-frame'>
          <div className='upper'>
            <div className='inner' style={{width:'100%'}}>
              <MenuButton open={open} onOpen={setOpen} onClose={setOpen} />
            </div>
            <div style={{flex:1, display:'flex', height:200, justifyContent:'center'}}>
              <div className='vertical-line'/>
            </div>
          </div>
          <div className='lower'>
            {/* <div className='socials-frame'>
              <div className='socials'>
                Fb
              </div>
            </div>
            <div className='socials-frame'>
              <div className='socials'>
                X
              </div>
            </div> */}

            <div style={{flex:1, display:'flex', height:200, justifyContent:'center'}}>
              <div className='vertical-line'/>
            </div>
            <div className='socials-frame'>
              <div className='socials'>
                Ln
              </div>
            </div>
            {/* <div className='socials-frame'>
              <div className='socials'>
                IG
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <div className='content-frame'>
        <div className='content'>
          <div
            className='full'
            style={{
              position:'fixed',
              display:open?'flex':'none',
              backgroundColor:"rgba(0,0,0,0.5)",
              zIndex:25
            }}
            onClick={()=>setOpen(false)}
          >
            <div
              onClick={e=>e.stopPropagation()}
              className='bg-dark-1'
              style={{
                height:'100vh', width:open?getMenuWidth():0,
                transition:"0.3s all ease-in 3s",
                boxSizing:'border-box', paddingTop:120, paddingBottom:120,
                paddingLeft:20, paddingRight:20
              }}
            >
              <div style={{display:'flex', flexDirection:'row', color:'#ccc'}}>
                {/* <img src={logo} style={{height:80, marginRight:30}}/> */}
                <h3>Navigation</h3>
              </div>
              <div>
                <ul>
                  <li onClick={()=>navigate("/")}>Home<ChevronRight id="slider-nav-next" color='white' htmlColor='white'  fontSize='medium' /></li>
                  <li onClick={()=>navigate("/","#about-section")}>About Us <ChevronRight id="slider-nav-next" color='white' htmlColor='white'  fontSize='medium' /></li>
                  <li onClick={()=>navigate("/","#project-section")}>Projects <ChevronRight id="slider-nav-next" color='white' htmlColor='white'  fontSize='medium' /></li>
                  <li onClick={()=>navigate("/","#services-section")}>Services <ChevronRight id="slider-nav-next" color='white' htmlColor='white'  fontSize='medium' /></li>
                </ul>
              </div>
            </div>
          </div>
          <Outlet/>
          {scrollUp===true && <div onClick={()=> gsap.to(window,{scrollTo:0, duration:2})} className='scroll-to-top bg-dark-4'>
            <ArrowUpward htmlColor='#F9BF26' fontSize='medium' />
          </div>}
        </div>
      </div>
    </div>
  );
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <FixedComponentFrame />,
    errorElement: <FixedComponentFrame></FixedComponentFrame>,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "portfolios",
        element: <Portfolios />,
      },
      {
        path: "portfolio/:slug",
        element: <Portfolio />,
      },
      {
        path: "portfolio",
        element: <Portfolios />,
      },
    ],
  },
]);

function App() {

  return (
          <div >
            <RouterProvider router={router} />
          </div>
  );
}

export default App;
/**
 * 
            <div className='inner' ref={swipeframe}>
              {images.map((item, idx)=>(
                <div  key={idx} className='box'>
                  <div  className='images' style={{backgroundImage:`url(${item})`,}}></div>
                </div>
              ))}
            </div>
 * <FirstTrial />
    <div id="app">
      <div style={{position:'fixed', zIndex:20}}>{index}</div>
      <div id="slider-frame">
        <FirstTrial />
      </div>
    </div>
 */