import './App.css';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Controller, EffectCoverflow, Autoplay } from 'swiper/modules';
import projects from './projects.json';
import { useParams } from 'react-router-dom';
export function Portfolio(props){
    const params = useParams();
    const project = projects[params.slug];
    return <div>
        <div id='portfolio-frame-wrapper' className="full bg-dark-4">
            <div className='floating-lines top'/>
            <div className='floating-lines right'/>
            {project ?
            <div id='portfolio-frame'>
                {/* <h1 className='primary-text-color'>{project?.name}</h1> */}
                <Swiper
                    grabCursor={true}
                    spaceBetween={30}
                    slidesPerView="auto"
                    modules={[Controller, Pagination]}
                    loop={true}
                    pagination={{el:".swiper-pagination", clickable:true, enabled:true}}
                >
                    {project?.images?.map((item, idx)=>(
                        <SwiperSlide key={item?.key}>
                            <div className='project-categories'>
                                <span>{idx+1}</span>
                            </div>
                            <img src={item?.image} />
                        </SwiperSlide>
                    ))}
                </Swiper>
                {/* <div className="swiper-pagination"></div> */}
            </div>
            :null}
            <div id='portfolio-split' className='bg-dark-4 row far-apart'>
                <div className='bg-dark-3' style={{width:'65%', display:'flex', height:'100%'}}>
                    <div className='flex-bottom-placer' style={{height:79, width:'100%', alignItems:'center', display:'flex'}}>
                        <div className='small-line' style={{width:100}}/> 
                        <span style={{color:'whitesmoke', marginLeft:50}}>{project?.images?.length} slides</span>
                    </div>
                </div>
                <div className='bg-dark-2' style={{width:'35%', display:'flex', height:'100%'}}>
                    <div className='flex-bottom-placer' style={{height:79, width:'100%', alignItems:'center', display:'flex'}}>
                        <div className="swiper-pagination" style={{position:'relative'}}></div>
                    </div>
                </div>
            </div>
        </div>
        <div className='full row'>
            <div className='left-split bg-white'>
                <div>
                    <h2>{project?.name}</h2>
                </div>
                <div>
                    <strong>
                        <small>{project.categories?.map(c=>c.name).join(', ')}</small>
                    </strong>
                </div>
                <div id='project-description' dangerouslySetInnerHTML={{__html:project?.description}}></div>
            </div>
            <div className='right-split bg-gray' style={{display:'flex',justifyContent:'center',}}>
                <div id='project-status' className='bg-dark-2' style={{height:200, width:'90%', padding:50, boxSizing:'border-box'}}>
                    <div className='row far-apart'>
                        <div>
                            <small className='subtee'>DATE:</small><br/>
                            <small className='subtext'>{project?.date}</small><br/>
                        </div>
                        <div>
                            <small className='subtee'>CLIENT:</small><br/>
                            <small className='subtext'>{project?.client}</small><br/>
                        </div>
                    </div>
                    <div style={{marginTop:25}} className='row far-apart'>
                        <div>
                            <small className='subtee'>STATUS:</small><br/>
                            <small className='subtext'>{project?.status}</small><br/>
                        </div>
                        <div>
                            <small className='subtee'>LOCATION:</small><br/>
                            <small className='subtext'>{project?.location}</small><br/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}