import { Link } from "react-router-dom"

export function ButtonOutline({type, asLink=false, yellow=false, className, href, ...props}){
    if(yellow)
        className+=' yellow'
    if(asLink)
        return <Link to={href} className={`button-outline ${className}`} {...props}>{props?.children}</Link>
    return <div className={`button-outline ${className}`} {...props}>{props?.children}</div>
}
export function Button({type, asLink=false, yellow=false,  className, href, ...props}){
    if(props?.yellow)
        className+=' yellow'
    if(asLink)
        return <Link to={href} className={`button bg-dark-4 ${className}`} {...props}>{props?.children}</Link>
    return <button type={type || "button"} className={`button bg-dark-4 ${className}`} {...props}>{props?.children}</button>
}