import axios from "axios";

export const client = axios.create({
    baseURL:"https://namariq.net"
});

export async function contact_us(payload){
    payload['contact'] = true;
    return client.post('process.php',payload);
}
export async function career_with_us(payload){
    return client.post('process.php',payload, {headers:{'Content-Type':'multipart/form-data'}});
}

export function submit_career(e){
    e.preventDefault();
    const form = new FormData(e.target);
    form.append("career", "true");
    career_with_us(form)
    .then(res=>{
        alert(res?.data?.message);
        e.target?.reset();
    })
    .catch(e=>alert(e?.response?.data?.message ? e?.response?.data?.message: "An error occured"));
}